/* eslint-disable react/prop-types */
import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { operationValidate } from '../../utils/role';
import { operationsUrl, transactionalPageUrl } from '../../constants';

import Imagotype from '../../assets/images/imagotype.svg';
import IconArrowBack from '../../assets/images/icon-back.svg';
import ImagotypeWhite from '../../assets/images/imagotype-white.svg';

import './header.scss';

const optionListUsers = [
  { value: 'Inicio', path: transactionalPageUrl },
  { value: '¿Cómo funciona?', blank: 'https://mipaquete.com/como-funciona/' },
  { value: 'Integra tu tienda online', blank: 'https://mipaquete.com/automatizacion/' },
  { value: 'Blogística', blank: 'https://mipaquete.com/blogistica/' },
  { value: 'Puntos mipaquete', path: `${transactionalPageUrl}/acumula-puntos` },
];

const optionListAdmin = [
  { value: 'Consultar', path: `${operationsUrl}/buscar-codigo-mp` },
  { value: 'Transportadoras', path: `${operationsUrl}/transportadora` },
  { value: 'Tarifas', path: `${operationsUrl}/reportes-transportadora` },
  { value: 'Informes', path: `${operationsUrl}/informacion-transportadora` },
  { value: 'Automatizaciones', path: `${operationsUrl}/automatizaciones` },
];

const HeaderMobile = ({
  backgroundWhite, hidden, titleSection, history,
}) => (
  <div className={`header mobile-mode ${hidden ? 'hidden' : ''}`}>
    <img
      src={backgroundWhite ? Imagotype : ImagotypeWhite}
      alt="imagotipo"
      className={`logo ${titleSection?.length <= 0 ? 'visible' : ''}`}
    />
    {titleSection?.length > 1 && (
      <div className="content-header-mobile">
        <img src={IconArrowBack} alt="back" onClick={history.goBack()} />
        <h1>{titleSection}</h1>
      </div>
    )}
  </div>
);

const HeaderDesktop = ({
  backgroundWhite, optionList, history,
}) => (
  <div className="header desktop-mode">
    <img
      src={backgroundWhite ? Imagotype : ImagotypeWhite}
      alt="imagotipo"
      className="logo"
    />
    <ul>
      {optionList.map((header) => (
        <li
          key={header.value}
          onClick={() => {
            if (header.path) {
              if (header.path.includes('http')) {
                window.location.assign(header.path);
              } else {
                history.push(header.path);
              }
            }
            if (header.blank) window.open(header.blank, '_blank');
          }}
        >
          {header.value}
        </li>
      ))}
    </ul>
  </div>
);

const Header = ({
  user, titleSection, history,
  hidden, backgroundWhite,
}) => (
  <>
    <HeaderMobile
      backgroundWhite={backgroundWhite}
      hidden={hidden}
      titleSection={titleSection}
      history={history}
    />
    <HeaderDesktop
      backgroundWhite={backgroundWhite}
      history={history}
      optionList={operationValidate(user) ? optionListAdmin : optionListUsers}
    />
  </>
);

Header.propTypes = {
  user: PropTypes.shape(),
  titleSection: PropTypes.string,
  hidden: PropTypes.bool,
  backgroundWhite: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    goBack: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

Header.defaultProps = {
  hidden: false,
  backgroundWhite: false,
  titleSection: '',
  user: null,
};

const mapStateToProps = (state) => ({
  isMobileDevice: state.mediaQuery.isMobileDevice,
  user: (state.auth) ? state.auth?.user : {},
});

export default compose(
  withRouter,
  connect(mapStateToProps),
)(Header);
