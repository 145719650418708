/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  usersMicroserviceUrl,
  customerKeyUsersMicroservice,
} from '../constants';

/**
  * servicio de login de usuario
  * @param {String} email - Correo del usuario a loguear
  * @param {String} password - Contraseña del usuario a loguear
*/
export const loginService = (query) => new Promise((resolve, reject) => {
  const endPoint = '/loginUser';
  axios.defaults.headers.common['customer-key'] = customerKeyUsersMicroservice;
  axios.post(`${usersMicroserviceUrl}${endPoint}`, query)
    .then((response) => resolve(response.data))
    .catch(({ response }) => reject(response));
});

export const loginSocialAccountService = (query) => new Promise((resolve, reject) => {
  const endPoint = '/loginUserSocialAccount';
  axios.defaults.headers.common['customer-key'] = customerKeyUsersMicroservice;
  axios.post(`${usersMicroserviceUrl}${endPoint}`, query)
    .then(({ data }) => resolve(data))
    .catch((e) => reject(e));
});

/**
  * servicio para obtener usuario por Id
  * @param {String} userId - Id del usuario a obtener
*/
export const getUserService = (userId) => new Promise((resolve, reject) => {
  const endPoint = '/getUser';
  axios.defaults.headers.common['customer-key'] = customerKeyUsersMicroservice;
  axios.get(`${usersMicroserviceUrl}${endPoint}/${userId}`)
    .then(({ data }) => resolve(data))
    .catch((e) => reject(e));
});

export const postUsersService = (userId) => new Promise((resolve, reject) => {
  const endPoint = '/getUsers';
  axios.defaults.headers.common['customer-key'] = customerKeyUsersMicroservice;
  axios.post(`${usersMicroserviceUrl}${endPoint}`, { ids: Array.isArray(userId) ? userId : [userId] })
    .then(({ data }) => resolve(data))
    .catch((e) => reject(e));
});

export const getUserLevelService = (userId) => new Promise((resolve, reject) => {
  const endPoint = '/levelCounter';
  axios.defaults.headers.common['customer-key'] = customerKeyUsersMicroservice;
  axios.get(`${usersMicroserviceUrl}${endPoint}/${userId}`)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});

export const pointsCounterService = (userId) => new Promise((resolve, reject) => {
  const endPoint = '/pointCounter';
  axios.defaults.headers.common['customer-key'] = customerKeyUsersMicroservice;
  axios.get(`${usersMicroserviceUrl}${endPoint}/${userId}`)
    .then((response) => { resolve(response.data); })
    .catch(({ response }) => { reject(response); });
});
