import crypto from 'crypto-js';
import { secretEncryptionKey } from '../constants';

export const encriptData = (data) => (
  crypto.AES.encrypt(JSON.stringify(data), secretEncryptionKey).toString()
);

export const decriptData = (string) => (
  crypto.AES.decrypt(string, secretEncryptionKey).toString(crypto.enc.Utf8)
);
