/* eslint-disable max-len */
import config from '../config/config';
import configFront from '../config/config.front.json';

export const { noveltiesMicroserviceUrl } = config; // Ruta microservicio de novedades
export const { deliveryCompaniesMicroserviceUrl } = config; // Ruta microservicio transportadoras
export const { usersMicroserviceUrl } = config; // Ruta microservicio usuarios
export const { routesMicroserviceUrl } = config; // Ruta microservicio rutas
export const { sendingsMicroserviceUrl } = config; // Ruta microservicio envíos
export const { transactionalPageUrl } = config; // Ruta página mi paquete reloaded
export const { collectionPaidUrl } = config; // Ruta página para recaudos

export const customerKeyNoveltiesMicroservice = 'ad849e98-b2e6-4492-b966-94d93245e003';
export const customerKeyDeliveryCompaniesMicroservice = '664e0f80-4a69-4042-9604-7797e95df194';
export const customerKeyUsersMicroservice = 'b0c6d2aa-4d53-11eb-ae93-0242ac130002';
export const customerKeyRoutesMicroservice = '74390816-b8bd-4c38-8c5d-4453256c110e';
export const customerKeySendingsMicroservice = 'b0c6d2aa-4d53-11eb-ae93-0242ac130002';

export const { urlConfigFile } = config; // Ruta archivo de configuración
export const { urlMassiveNoveltiesTemplateExcel } = config; // url archivo de novedades masivo en desarrollo
export const { adminUrl } = config;
export const { operationsUrl } = config;

export const nameTokenUserStorage = 'userDataToken'; // user data token name
export const secretEncryptionKey = 'de5d19a1-37e4-44bf-a67e-8f08e2d5abf5'; // llave secreta de encriptación

export const bucketImageName = configFront.uploadImages.bucketNameDev; // nombre del bucket de almacenamiento de imagenes
export const regionBucket = configFront.uploadImages.region; // region del bucket de almacenamiento de imagenes
export const bucketAccessKeyId = configFront.uploadImages.aws_access_key_id; // id del bucket de almacenamiento de imagenes
export const bucketSecretAccessKey = configFront.uploadImages.aws_secret_access_key; // llave secreta del bucket de almacenamiento de imagenes
