import axios from 'axios';

import {
  deliveryCompaniesMicroserviceUrl,
  customerKeyDeliveryCompaniesMicroservice,
} from '../constants';

/**
  * obetener las transportadoras
*/
export const getDeliveryCompaniesService = () => new Promise((resolve, reject) => {
  const endPoint = '/getdeliverycompanies';
  axios.defaults.headers.common['customer-key'] = customerKeyDeliveryCompaniesMicroservice;
  axios.get(`${deliveryCompaniesMicroserviceUrl}${endPoint}`)
    .then((response) => resolve(response.data))
    .catch(({ response }) => reject(response));
});

export default {
  getDeliveryCompaniesService,
};
