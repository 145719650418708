/**
 * funcion para filtrar datos un array
 * @param {Object[]} businessFlags array de secciones a filtrar
 * @param {String} section nombre de la sección a buscar
 * @param {String} feature nombre de la feature a buscar en la sección dada
 * @param {String} userEmail email del usuario que está logueado
 * @returns devuelve si es visible o no deacuerdo al archivo de configuración
 */
export const showDependingOnBusinessFlag = ({
  businessFlags, section, feature, userEmail,
}) => {
  let enabled = false;
  let sectionFound = null;

  if (section && businessFlags) {
    sectionFound = businessFlags.find((itemSection) => itemSection.name === section);
    if (sectionFound) enabled = true;
  }

  if (enabled && feature && sectionFound && sectionFound.features) {
    const featureFound = sectionFound.features.find((featureSection) => (
      featureSection.name === feature
    ));
    if (!featureFound) enabled = false;
  }

  if (enabled && userEmail && sectionFound && sectionFound.enabledForUsers) {
    const userFound = sectionFound.enabledForUsers.find((email) => email === userEmail);
    if (!userFound) enabled = false;
  }

  return enabled;
};

export default showDependingOnBusinessFlag;
