import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { fullWidth } from '../../utils/generals';
import { operationValidate } from '../../utils/role';
import dataSections from '../../constants/dataSections';
import { getShippingsWithNoveltiesService } from '../../services/novelties.service';

import LeftSideMenuCards from './leftSideMenuCards';

import './leftSideMenu.scss';

const LeftSideMenu = ({ history }) => {
  const user = useSelector((state) => state.auth.user);
  const [currentSection, setCurrentSection] = useState('');
  const [numberOfNovelties, setNumberOfNovelties] = useState(0);

  useEffect(() => {
    const getShippingWithNoveltiesFunction = async () => {
      if (user && user._id) {
        const responseShippingWithNovelties = await getShippingsWithNoveltiesService({
          userId: user._id, state: 'Sin gestión', page: 1,
        });
        setNumberOfNovelties(responseShippingWithNovelties.totalDocuments);
      }
    };

    if (user && !user.role) getShippingWithNoveltiesFunction();
  }, [user]);

  useEffect(() => {
    setCurrentSection(history.location.pathname);
  }, [history.location.pathname]);

  const dataToMap = () => {
    if (user && user.role) return dataSections.internalAutenticated;
    if (user && !user.role) return dataSections.userAutenticated;
    return dataSections.notAutenticated;
  };

  return (
    <div
      id={`${operationValidate(user) ? 'left-side-menu-hover' : 'left-side-menu'}`}
      className={fullWidth(currentSection, user) ? 'hidden' : ''}
    >
      {dataToMap().map((option) => (
        !option.noLeftMenu && (
          <LeftSideMenuCards
            key={`option-left-side-menu-${option.label}`}
            img={option.Icon && option.IconDark && (
              currentSection === option.url ? option.Icon : option.IconDark
            )}
            active={currentSection === option.url}
            shadding={option.shadding}
            label={option.label}
            elementRight={(option.label === 'Novedades' && numberOfNovelties > 0) ? (
              <span className="count-unread">{numberOfNovelties}</span>
            ) : null}
            onClick={() => {
              if (option.url.includes('http')) {
                window.location.assign(option.url);
              } else {
                history.push(option.url);
              }
            }}
            itemsHover={operationValidate(user)}
          />
        )
      ))}
    </div>
  );
};

LeftSideMenu.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withRouter(LeftSideMenu);
