/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose, bindActionCreators } from 'redux';

import { extractToSpecialCharacter, capitalizeWords } from '../../utils/strings';
import { showMenuAction, closeMenuAction } from '../../store/actions/menu.actions';
import { hiddenRigthMenu } from '../../utils/generals';
import { transactionalPageUrl } from '../../constants';

import Typography from '../typography';
import Button from '../button';

import IconHome from '../../assets/images/home.svg';
import IconMenu from '../../assets/images/menuIcons/menu.svg';
import WhiteLogo from '../../assets/images/menuIcons/white-logo.svg';

import './rigthSideMenu.scss';
import './avatarIcon.scss';

const IconUser = (props) => (
  <div className="avatarIcon">
    <h1>{props.name.charAt(0).toUpperCase()}</h1>
  </div>
);

const OptionsMenuDesktopUser = ({
  isVisible, logoutFunctionOwner, historyProfile, setshowMenuTogge,
}) => (
  <div className={`toggle-modal ${!isVisible ? 'toggle-modal-none' : ''}`}>
    <div className="content-toggle-menu" onClick={setshowMenuTogge}>
      <div onClick={historyProfile}>
        <Typography type="superTitle-italic" className="profile">
          Mi perfil
        </Typography>
      </div>
      <hr />
      <div onClick={logoutFunctionOwner}>
        <Typography type="superTitle-italic" className="logout">
          Cerrar sesión
        </Typography>
      </div>
    </div>
  </div>
);

const ListItemMobile = ({
  user, showMenu, closeMenu, history, logoutFunctionOwner, historyProfile,
}) => (
  <div
    id="content__menu"
    className={showMenu ? 'menu-displayed-mobile' : ''}
  >
    <div className="menu">
      {user ? (
        <div className="item-menu" onClick={historyProfile}>
          <span>Mi perfil</span>
        </div>
      ) : (
        <div className="item-menu">
          <div className="buttons-for-login-or-register">
            <Button
              text="Ingresa"
              type="secondary"
              className="border-none"
              onClick={() => {
                closeMenu();
                history.push('/ingreso');
              }}
            />
            <Button
              text="Regístrate"
              type="primary"
              onClick={() => {
                closeMenu();
                history.push('/registro');
              }}
            />
          </div>
        </div>
      )}
      <hr />
      <div
        className="item-menu second-part"
        onClick={() => {
          closeMenu();
          window.open('https://mipaquete.com/como-funciona/', '_blank');
        }}
      >
        <span>¿Cómo funciona?</span>
      </div>
      <div
        className="item-menu second-part"
        onClick={() => {
          closeMenu();
          window.open('https://mipaquete.com/automatizacion/', '_blank');
        }}
      >
        <span>Integra tu tienda online</span>
      </div>
      <div
        className="item-menu second-part"
        onClick={() => {
          closeMenu();
          window.open('https://mipaquete.com/blog/', '_blank');
        }}
      >
        <span>Blogística</span>
      </div>
      {!user && (
        <div
          className="item-menu"
          type="primary"
          onClick={() => {
            closeMenu();
            window.location.assign(`${transactionalPageUrl}/acumula-puntos`);
          }}
        >
          <span>Puntos mipaquete</span>
        </div>
      )}

    </div>

    {user && (
      <div className="logout" onClick={logoutFunctionOwner}>
        <hr />
        <span>Cerrar sesión</span>
      </div>
    )}
  </div>
);

const MobileMode = ({
  showMenu, history, isMobileDevice, closeMenuFunction,
  showMenuFunction, showMenuTogge, setshowMenuTogge,
  user, logoutFunctionOwner, historyProfile, businessFlags, notificationsReceiver,
  myNegotiations, myDirectionsSettings, myTransportersSettings, userLevel,
}) => (
  <>
    <img
      className={`
        icon-home
        ${!showMenu
          && history.location.pathname !== '/'
          && !hiddenRigthMenu(history.location.pathname) ? 'visible' : ''
        }
      `}
      src={IconHome}
      alt=""
      onClick={() => {
        window.location.assign(`${transactionalPageUrl}`);
      }}
    />

    <div
      id="background__menu"
      className={`
        ${showMenu ? 'menu-displayed-mobile' : ''}
        ${hiddenRigthMenu(history.location.pathname) && isMobileDevice ? 'hidden' : ''}
        `}
      onClick={() => showMenu && closeMenuFunction()}
    />

    <div
      id="button__menu"
      onClick={() => (showMenu ? closeMenuFunction() : isMobileDevice && showMenuFunction())}
      className={`
        ${showMenu ? 'menu-displayed-mobile' : ''}
        ${!isMobileDevice ? 'desktop-mode' : ''}
        ${!isMobileDevice && showMenuTogge ? 'menu-displayed-desktop' : ''}
        ${hiddenRigthMenu(history.location.pathname) && isMobileDevice ? 'hidden' : ''}
        `}
    >
      <div id="background__button__menu" />
      <div id="content__button__menu" onClick={() => setshowMenuTogge(!showMenuTogge)}>
        <div className="container-user-data__content__button__menu">
          {user ? (
            <>
              <span>
                {capitalizeWords(`
                  ${user.name && extractToSpecialCharacter(user.name, ' ')[0]}
                  ${user.surname && extractToSpecialCharacter(user.surname, ' ')[0]}
                `)}
              </span>
              {user.image ? (
                <div id="user-image__content__button__menu">
                  <img src={user.image} alt="" />
                </div>
              ) : (
                <IconUser name={user.name} />
              )}
            </>
          ) : (
            <>
              <img
                className={`icon__user-data__content__button__menu ${showMenu ? '' : 'visible'}`}
                src={IconMenu}
                alt=""
              />
              <img
                className={`white-logo ${showMenu ? 'visible' : ''}`}
                src={WhiteLogo}
                alt=""
              />
            </>
          )}
        </div>
      </div>
    </div>

    <ListItemMobile
      user={user}
      showMenu={showMenu}
      closeMenu={closeMenuFunction}
      history={history}
      historyProfile={historyProfile}
      logoutFunctionOwner={logoutFunctionOwner}
      businessFlags={businessFlags}
      notificationsReceiver={notificationsReceiver}
      myNegotiations={myNegotiations}
      myTransportersSettings={myTransportersSettings}
      myDirectionsSettings={myDirectionsSettings}
      userLevel={userLevel}
    />
  </>
);

const DesktopMode = ({
  showMenu, history, isMobileDevice, closeMenuFunction,
  showMenuFunction, showMenuTogge, setshowMenuTogge,
  user, logoutFunctionOwner, historyProfile, myDirectionsSettings, businessFlags,
  notificationsReceiver, myTransportersSettings, userLevel,
}) => (
  <>
    <div
      id="background__menu"
      className={showMenu ? 'menu-displayed-mobile' : ''}
      onClick={() => showMenu && closeMenuFunction()}
    />

    <div
      id="button__menu"
      onClick={() => (showMenu ? closeMenuFunction() : user && showMenuFunction())}
      className={`
        ${showMenu ? 'menu-displayed-mobile' : ''}
        ${!isMobileDevice ? 'desktop-mode' : ''}
        ${!isMobileDevice && showMenuTogge ? 'menu-displayed-desktop' : ''}
        ${hiddenRigthMenu(history.location.pathname) && isMobileDevice ? 'hidden' : ''}
      `}
    >
      <div id="background__button__menu" onClick={() => { setshowMenuTogge(!showMenuTogge); }} />
      <div id="content__button__menu" onClick={() => { setshowMenuTogge(!showMenuTogge); }}>
        <div className="container-user-data__content__button__menu">
          {user ? (
            <>
              <span>
                {capitalizeWords(`
                  ${user.name && extractToSpecialCharacter(user.name, ' ')[0]}
                  ${user.surname && extractToSpecialCharacter(user.surname, ' ')[0]}
                `)}
              </span>
              {user.image ? (
                <div id="user-image__content__button__menu">
                  <img src={user.image} alt="" />
                </div>
              ) : (
                <IconUser name={user.name} />
              )}
            </>
          ) : (
            <>
              <span className="to-login login" onClick={() => history.push('/ingreso')}>
                Ingresa
              </span>
              <span className="to-login create" onClick={() => history.push('/registro')}>
                Regístrate
              </span>
            </>
          )}
        </div>
      </div>
    </div>

    {user && (
      <OptionsMenuDesktopUser
        isVisible={showMenu}
        logoutFunctionOwner={logoutFunctionOwner}
        historyProfile={historyProfile}
        myDirectionsSettings={myDirectionsSettings}
        notificationsReceiver={notificationsReceiver}
        myTransportersSettings={myTransportersSettings}
        userLevel={userLevel}
        user={user}
        setshowMenuTogge={() => setshowMenuTogge(false)}
        businessFlags={businessFlags}
      />
    )}
  </>
);

const RigthSideMenu = ({
  showMenu, showMenuFunction, closeMenuFunction, businessFlags,
  history, isMobileDevice, user,
}) => {
  const [showMenuTogge, setshowMenuTogge] = useState(false);

  const logoutFunctionOwner = () => {
    setshowMenuTogge(false);
    closeMenuFunction();
    window.location.replace(`${transactionalPageUrl}/?intercommunicationData={"action":"logout"}`);
  };

  const historyProfile = () => {
    setshowMenuTogge(false);
    closeMenuFunction();
    window.location.assign(`${transactionalPageUrl}/perfil`);
  };

  const notificationsReceiver = () => {
    setshowMenuTogge(false);
    closeMenuFunction();
    window.location.assign(`${transactionalPageUrl}/notificaciones-a-destinatarios`);
  };

  const myNegotiations = () => {
    setshowMenuTogge(false);
    closeMenuFunction();
    window.location.assign(`${transactionalPageUrl}/mis-negociaciones`);
  };

  const myTransportersSettings = () => {
    setshowMenuTogge(false);
    closeMenuFunction();
    window.location.assign(`${transactionalPageUrl}/mis-transportadoras`);
  };

  const myDirectionsSettings = () => {
    setshowMenuTogge(false);
    closeMenuFunction();
    window.location.assign(`${transactionalPageUrl}/mis-direcciones`);
  };

  const userLevel = () => {
    setshowMenuTogge(false);
    closeMenuFunction();
    window.location.assign(`${transactionalPageUrl}/acumula-puntos`);
  };

  const closeMenu = () => {
    setshowMenuTogge(false);
    closeMenuFunction();
  };

  return isMobileDevice ? (
    <MobileMode
      showMenu={showMenu}
      history={history}
      isMobileDevice={isMobileDevice}
      closeMenuFunction={closeMenuFunction}
      showMenuFunction={showMenuFunction}
      showMenuTogge={showMenuTogge}
      setshowMenuTogge={setshowMenuTogge}
      user={user}
      logoutFunctionOwner={logoutFunctionOwner}
      historyProfile={historyProfile}
      businessFlags={businessFlags}
      notificationsReceiver={notificationsReceiver}
      myNegotiations={myNegotiations}
      myTransportersSettings={myTransportersSettings}
      myDirectionsSettings={myDirectionsSettings}
      userLevel={userLevel}
    />
  ) : (
    <DesktopMode
      showMenu={showMenu}
      history={history}
      isMobileDevice={isMobileDevice}
      closeMenuFunction={closeMenu}
      showMenuFunction={showMenuFunction}
      showMenuTogge={showMenuTogge}
      setshowMenuTogge={setshowMenuTogge}
      user={user}
      logoutFunctionOwner={logoutFunctionOwner}
      historyProfile={historyProfile}
      myDirectionsSettings={myDirectionsSettings}
      myTransportersSettings={myTransportersSettings}
      notificationsReceiver={notificationsReceiver}
      userLevel={userLevel}
      businessFlags={businessFlags}
    />
  );
};

RigthSideMenu.propTypes = {
  user: PropTypes.shape(),
  isMobileDevice: PropTypes.bool.isRequired,
  showMenu: PropTypes.bool.isRequired,
  showMenuFunction: PropTypes.func.isRequired,
  closeMenuFunction: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  }).isRequired,
  businessFlags: PropTypes.shape().isRequired,
};

RigthSideMenu.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  showMenu: state.menu.show,
  isMobileDevice: state.mediaQuery.isMobileDevice,
  user: state.auth.user,
  businessFlags: state.globalData.businessFlags || [],
});

const mapDispactchToProps = (dispatch) => ({
  closeMenuFunction: bindActionCreators(closeMenuAction, dispatch),
  showMenuFunction: bindActionCreators(showMenuAction, dispatch),
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispactchToProps),
)(RigthSideMenu);
