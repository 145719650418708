import React from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';

import initStore from './store/store';

import RouterApp from './router';

ReactDOM.render(
  (
    <Provider store={initStore()}>
      <RouterApp />
    </Provider>
  ), document.getElementById('root'),
);
