export const fullWidth = (currentSection, user) => {
  if (currentSection === '/' && user) return true;
  return false;
};

export const backgroundFigureFullWidth = (currentSection) => {
  if (currentSection === '/novedades') return true;
  return false;
};

export const backgroundWhite = (currentSection) => {
  if (currentSection === '/novedades') return true;
  return false;
};

export const backgroundFigureWhite = (pathName) => {
  if (pathName === '/novedades') return true;
  return false;
};

export const backgroundFigureBlue = (currentSection) => {
  if (currentSection === '/novedades') return true;
  return false;
};

export const backgroundFigurePosition = (pathName) => {
  if (pathName === '/novedades') return 'bottom-right';
  return 'top-left';
};

export const hiddenRigthMenu = (currentSection) => {
  if (
    currentSection === '/perfil'
    || currentSection === '/editar-perfil'
    || currentSection === '/cambiar-contrasena'
  ) {
    return true;
  }
  return false;
};

export default {
  fullWidth,
};
