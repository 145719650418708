/* eslint-disable default-param-last */
import {
  AUTH_IN, AUTH_OUT, AUTH_UPDATE, AUTH_UPDATE_TOKEN,
} from '../actions/type.actions';

const initialState = {
  user: null,
  logged: false,
  token: null,
};

const parserUser = (user) => {
  if (user) {
    const newUser = user;
    delete newUser.password;
    return newUser;
  }
  return null;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_OUT: {
      return initialState;
    }
    case AUTH_IN: {
      return {
        logged: true,
        user: parserUser(action.payload),
      };
    }
    case AUTH_UPDATE: {
      return {
        logged: true,
        user: parserUser(action.payload),
      };
    }
    case AUTH_UPDATE_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
