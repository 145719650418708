/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import React, {
  Suspense, lazy, useEffect, useState,
} from 'react';
import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { bindActionCreators } from 'redux';
import { useMediaQuery } from 'react-responsive';

import configS3 from './config/config.front.json';

import { backgroundWhite } from './utils/generals';
import { decriptData } from './utils/auth';
import { capitalizeWords } from './utils/strings';
import { transactionalPageUrl } from './constants';
import { setHeaderRequest } from './services/config';
import { loginService, loginSocialAccountService } from './services/users.service';
import { SignInAction } from './store/actions/auth.actions';
import { getLocationsService } from './services/location.service';
import { showDependingOnBusinessFlag } from './utils/businessFlag';
import { LoadDataGlobalAction } from './store/actions/global.actions';
import { getDeliveryCompaniesService } from './services/deliveryCompany.service';
import { isMobileDeviceAction, isNotMobileDeviceAction } from './store/actions/mediaQuery.actions';

import Header from './components/header';
import Loading from './components/loading';
import Background from './components/background';
import UrlControl from './components/urlControl';
import LeftSideMenu from './components/leftSideMenu';
import RigthSideMenu from './components/rightSideMenu';

import './index.scss';

const HomeScreen = lazy(() => import('./screens/homeScreen'));
const NoveltiesScreen = lazy(() => import('./screens/noveltiesScreen'));

const RouterApp = ({
  SignInFunction, LoadDataGlobalFunction, businessFlags,
  isMobileDeviceFunction, isNotMobileDeviceFunction, user,
}) => {
  const [loading, setLoading] = useState(true);
  const [pathname, setPathname] = useState(null);

  if (useMediaQuery({ query: '(max-width: 1023px)' })) {
    isMobileDeviceFunction();
  } else {
    isNotMobileDeviceFunction();
  }

  useEffect(() => {
    setPathname(window.location.pathname);
  }, [window.location.pathname, pathname]);

  useEffect(() => {
    (async () => {
      await setHeaderRequest();
      const config = configS3;
      let responseLocations = [];
      let responseDeliveryCompanies = [];
      Promise.allSettled([
        getLocationsService(),
        getDeliveryCompaniesService(),
      ]).then((response) => {
        response.forEach((item, index) => {
          if (item.status === 'fulfilled') {
            if (index === 0) responseLocations = item.value;
            if (index === 1) responseDeliveryCompanies = item.value;
          }
        });
        LoadDataGlobalFunction({
          locations: responseLocations,
          deliveryCompaniesConfig: config.configTransporters,
          businessFlags: config.businessFlags,
          deliveryCompanies: responseDeliveryCompanies.slice().map((deliveryCompany) => {
            deliveryCompany.name = capitalizeWords(deliveryCompany.name);
            return deliveryCompany;
          }),
        });
      });
    })();

    (async () => {
      const queryParams = queryString.parse(window.location.search);
      const intercommunicationData = queryParams.intercommunicationData
        ? JSON.parse(queryParams.intercommunicationData) : {};
      delete queryParams.intercommunicationData;
      let dataToLogin = {};

      if (intercommunicationData.token) {
        try {
          intercommunicationData.token = intercommunicationData.token.replace(/ /g, '+');
          const decryptedToken = await decriptData(intercommunicationData.token);
          dataToLogin = {
            data: JSON.parse(decryptedToken),
            keep: intercommunicationData.keep || false,
          };
        } catch {
          dataToLogin = { data: {}, keep: false };
        }
      } else {
        let urlToReturnAfterLogin = `${window.location.origin}${window.location.pathname}?${queryString.stringify(queryParams)}${window.location.hash}`;
        urlToReturnAfterLogin = urlToReturnAfterLogin.split('').reverse();
        while (urlToReturnAfterLogin[0] === '?' || urlToReturnAfterLogin[0] === '#') {
          urlToReturnAfterLogin.splice(0, 1);
        }
        urlToReturnAfterLogin = urlToReturnAfterLogin.reverse().join('');
        window.location.replace(`${transactionalPageUrl}/ingreso?intercommunicationData={"urlToReturnAfterLogin":"${urlToReturnAfterLogin}"}`);
      }

      const userToLogin = dataToLogin?.data?.user ?? {};
      if (userToLogin.accessToken && userToLogin.socialUserId && userToLogin.socialNetwork) {
        const { accessToken, socialUserId, socialNetwork } = userToLogin;
        const response = await loginSocialAccountService({
          accessToken, socialUserId, socialNetwork,
        });
        response.user.accessToken = accessToken;
        response.user.socialUserId = socialUserId;
        response.user.socialNetwork = socialNetwork;
        setHeaderRequest(response.token);
        SignInFunction(response.user);
        setLoading(false);
      } else if (userToLogin.email && userToLogin.password) {
        const newToken = await loginService({
          email: userToLogin.email,
          password: userToLogin.password,
        });
        newToken.user.password = userToLogin.password;
        setHeaderRequest(newToken.token);
        SignInFunction(newToken.user);
        setLoading(false);
      }
    })();
  }, []);

  if (!businessFlags || !user || loading) return <Loading />;

  if (
    businessFlags && user
    && showDependingOnBusinessFlag({
      businessFlags, section: 'noveltiesCenter', userEmail: user.email,
    })
  ) {
    return (
      <Router>
        <Suspense fallback={<Loading />}>
          <>
            <UrlControl />
            <Background />
            <Header backgroundWhite={backgroundWhite(pathname)} />
            <LeftSideMenu />
            <RigthSideMenu />
            <Switch>
              <Route exact path="/novedades" component={NoveltiesScreen} />
              <Route path="/">
                <HomeScreen setPathName={setPathname} />
              </Route>
            </Switch>
          </>
        </Suspense>
      </Router>
    );
  }

  window.location.replace(transactionalPageUrl);
  return null;
};

RouterApp.propTypes = {
  LoadDataGlobalFunction: PropTypes.func.isRequired,
  SignInFunction: PropTypes.func.isRequired,
  isMobileDeviceFunction: PropTypes.func.isRequired,
  isNotMobileDeviceFunction: PropTypes.func.isRequired,
  location: PropTypes.shape({
    search: PropTypes.shape(),
  }).isRequired,
  businessFlags: PropTypes.shape(),
  user: PropTypes.shape(),
};

RouterApp.defaultProps = {
  businessFlags: null,
  user: null,
};

const mapStateToProps = (state) => ({
  user: (state.auth) ? state.auth.user : null,
  businessFlags: state.globalData.businessFlags || null,
});

const mapDispactchToProps = (dispatch) => ({
  LoadDataGlobalFunction: bindActionCreators(LoadDataGlobalAction, dispatch),
  SignInFunction: bindActionCreators(SignInAction, dispatch),
  isMobileDeviceFunction: bindActionCreators(isMobileDeviceAction, dispatch),
  isNotMobileDeviceFunction: bindActionCreators(isNotMobileDeviceAction, dispatch),
});

export default connect(mapStateToProps, mapDispactchToProps)(RouterApp);
