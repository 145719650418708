/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import './typography.scss';

const Typography = ({
  className, type, children, style,
}) => {
  switch (type) {
    case 'superTitle-italic':
      return <h1 className={`italic ${className}`} style={style}>{children}</h1>;
    case 'superTitle':
      return <h1 className={className} style={style}>{children}</h1>;
    case 'title':
      return <h2 className={className} style={style}>{children}</h2>;
    case 'subtitle':
      return <h3 className={className} style={style}>{children}</h3>;
    case 'field':
      return <span className={`${className} ${type}`} style={style}>{children}</span>;
    case 'value':
      return <span className={`${className} ${type}`} style={style}>{children}</span>;
    case 'paragraph':
      return <p className={className} style={style}>{children}</p>;
    default:
      return <span className={className} style={style}>{children}</span>;
  }
};

Typography.propTypes = {
  type: PropTypes.oneOf([
    'superTitle-italic', 'superTitle', 'title', 'subtitle', 'field', 'value', 'paragraph',
  ]).isRequired,
  className: PropTypes.string,
  children: PropTypes.shape(),
  style: PropTypes.shape(PropTypes.shape()),
};

Typography.defaultProps = {
  className: null,
  children: null,
  style: null,
};

export default Typography;
