import React from 'react';
import PropTypes from 'prop-types';

import './button.scss';

const Button = ({
  type, className, onClick, disabled, loading, children, text,
}) => (
  <button
    className={`button ${type} ${className}`}
    type="button"
    onClick={onClick}
    disabled={disabled || loading}
  >
    {loading ? <div className="spinner-button" /> : children || text}
  </button>
);

Button.propTypes = {
  children: PropTypes.element,
  text: PropTypes.string,
  type: PropTypes.oneOf([
    'primary', 'secondary', 'tertiary', 'quaternary', 'danger',
  ]).isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
};

Button.defaultProps = {
  children: null,
  text: null,
  className: null,
  disabled: false,
  loading: false,
};

export default Button;
