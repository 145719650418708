/* eslint-disable max-len */
import axios from 'axios';

import {
  noveltiesMicroserviceUrl,
  customerKeyNoveltiesMicroservice,
} from '../constants';
import { MPCodeVerify } from '../utils/strings';

/**
  * crear una novedad asociada a un envío
  * @param {String} mpCodeOrGuideNumber - Código MP (MP201342) o número de guía del envío
  * @param {Number} noveltyCode - codigo de novedad (101, 102...)
  * @param {String} observations - observaciones del proceso de novedad
  * @param {String} creatorUserId - id del usuario que reporta la novedad
  * @param {String} reportDate - fecha de reporte de novedad (09/09/2021 13:20)
*/
export const createNoveltyService = (mpCodeOrGuideNumber, noveltyCode, observations, creatorUserId, reportDate, noveltyData) => new Promise((resolve, reject) => {
  const endPoint = '/createnovelties';
  axios.defaults.headers.common['customer-key'] = customerKeyNoveltiesMicroservice;
  axios.post(`${noveltiesMicroserviceUrl}${endPoint}`, {
    ...MPCodeVerify(mpCodeOrGuideNumber) ? {
      mpCode: MPCodeVerify(mpCodeOrGuideNumber),
    } : {
      guide: mpCodeOrGuideNumber,
    },
    noveltyCode,
    ...observations && { description: observations },
    creatorUser: creatorUserId,
    reportDate,
    ...noveltyData && noveltyData,
  })
    .then((response) => resolve(response.data))
    .catch(({ response }) => reject(response));
});

/**
  * obtener los códigos de novedades
  * @param {String} data - datos para anexar a la query, el objeto puede contenter:
  * module
*/
export const getNoveltyCodesService = (data) => new Promise((resolve, reject) => {
  const endPoint = '/noveltycodes';
  axios.defaults.headers.common['customer-key'] = customerKeyNoveltiesMicroservice;
  axios.post(`${noveltiesMicroserviceUrl}${endPoint}`, { ...data })
    .then((response) => resolve(response.data))
    .catch(({ response }) => reject(response));
});

/**
  * obtener los códigos de solucion de novedades
*/
export const getNoveltySolutionCodesService = () => new Promise((resolve, reject) => {
  const endPoint = '/noveltysolutions';
  axios.defaults.headers.common['customer-key'] = customerKeyNoveltiesMicroservice;
  axios.get(`${noveltiesMicroserviceUrl}${endPoint}`)
    .then((response) => resolve(response.data))
    .catch(({ response }) => reject(response));
});

/**
  * obtener los envíos con novedad
  * @param {String} data - datos para anexar a la query, el objeto puede contenter:
  * guideNumber, mpCode, noveltyId, date, state, page, numberItemsPerPage,
  * userId, userEmail, userDocumentNumber, userCellphone,
*/
export const getShippingsWithNoveltiesService = (
  data,
) => new Promise((resolve, reject) => {
  const endPoint = '/getnovelties';
  axios.defaults.headers.common['customer-key'] = customerKeyNoveltiesMicroservice;
  axios.post(`${noveltiesMicroserviceUrl}${endPoint}`, {
    ...data.sort ? { sort: data.sort } : {},
    ...data.guideNumber ? { guide: data.guideNumber } : {},
    ...data.mpCode ? { mpCode: data.mpCode } : {},
    ...data.noveltyId ? { noveltyId: data.noveltyId } : {},
    ...data.date ? { date: data.date } : {},
    ...data.state ? { state: data.state } : {},
    ...data.sortOption ? { sortOption: data.sortOption } : {},
    currentPage: data.page,
    ...data.numberItemsPerPage ? { size: data.numberItemsPerPage } : {},
    ...data.userId ? { userId: data.userId } : {},
    ...data.userEmail ? { userEmail: data.userEmail } : {},
    ...data.userDocumentNumber ? { documentNumberUser: data.userDocumentNumber } : {},
    ...data.userCellphone ? { cellphone: data.userCellphone } : {},
    ...data.deliveries ? { deliveries: data.deliveries } : {},
    ...data.noveltyCode ? { noveltyCode: data.noveltyCode } : {},
    ...data.receiverCellphone ? { receiverCellphone: data.receiverCellphone } : {},
    ...data.size ? { size: data.size } : {},
  })
    .then((response) => resolve(response.data))
    .catch(({ response }) => reject(response));
});

/**
  * obetener los envíos con novedad
  * @param {String} data - datos para anexar a la query, el objeto puede contenter:
  * noveltyId, displayed, userId, message, description, noveltyCode,
*/
export const updateNoveltyService = (data) => new Promise((resolve, reject) => {
  const endPoint = '/updatenovelties';
  axios.defaults.headers.common['customer-key'] = customerKeyNoveltiesMicroservice;
  axios.post(`${noveltiesMicroserviceUrl}${endPoint}`, {
    noveltyId: data.noveltyId,
    ...typeof data.displayed === 'boolean' ? { displayed: data.displayed } : {},
    ...data.message && data.userId ? {
      comment: {
        userId: data.userId,
        message: data.message,
      },
    } : {},
    ...data.description ? { description: data.description } : {},
    ...data.noveltyCode ? { noveltyCode: data.noveltyCode } : {},
  })
    .then((response) => resolve(response.data))
    .catch(({ response }) => reject(response));
});

/**
  * gestionar o solucionar novedad
  * @param {Object} data - datos para anexar a la query, el objeto puede contenter:
  * por definir...
*/
export const solveNoveltiesService = (data) => new Promise((resolve, reject) => {
  const endPoint = '/solvenovelties';
  axios.defaults.headers.common['customer-key'] = customerKeyNoveltiesMicroservice;
  axios.post(`${noveltiesMicroserviceUrl}${endPoint}`, data)
    .then((response) => resolve(response.data))
    .catch(({ response }) => reject(response));
});

/**
  * aprobar solucion dada por un usuario
  * @param {Object} data - datos para anexar a la query, el objeto puede contenter:
  * por definir...
*/
export const approveSolutionService = (data) => new Promise((resolve, reject) => {
  const endPoint = '/manageSolution';
  axios.defaults.headers.common['customer-key'] = customerKeyNoveltiesMicroservice;
  axios.post(`${noveltiesMicroserviceUrl}${endPoint}`, data)
    .then((response) => resolve(response.data))
    .catch(({ response }) => reject(response));
});

/**
  * eliminar una novedad
  * @param {Object} data - datos para anexar a la query, el objeto puede contenter:
  * por definir...
*/
export const deleteNoveltyService = (data) => new Promise((resolve, reject) => {
  const endPoint = '/deleteNovelties';
  axios.defaults.headers.common['customer-key'] = customerKeyNoveltiesMicroservice;
  axios.post(`${noveltiesMicroserviceUrl}${endPoint}`, data)
    .then((response) => resolve(response.data))
    .catch(({ response }) => reject(response));
});

export const updateNoveltyUserService = (data) => new Promise((resolve, reject) => {
  const endPoint = '/updateNoveltiesUser';
  axios.defaults.headers.common['customer-key'] = customerKeyNoveltiesMicroservice;
  axios.post(`${noveltiesMicroserviceUrl}${endPoint}`, data)
    .then((response) => resolve(response.data))
    .catch(({ response }) => reject(response));
});

export const getAgencyUsers = () => new Promise((resolve, reject) => {
  const endPoint = '/getAgencyUsers';
  axios.defaults.headers.common['customer-key'] = customerKeyNoveltiesMicroservice;
  axios.get(`${noveltiesMicroserviceUrl}${endPoint}`)
    .then((response) => resolve(response.data))
    .catch(({ response }) => reject(response));
});

export default {
  getAgencyUsers,
  createNoveltyService,
  deleteNoveltyService,
  getNoveltyCodesService,
  updateNoveltyUserService,
  getNoveltySolutionCodesService,
  getShippingsWithNoveltiesService,
};
