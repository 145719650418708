const environment = {
  DEV: {
    noveltiesMicroserviceUrl: 'https://core.dev.mipaquete.com/novelties', // Ruta microservicio de novedades desarrollo
    deliveryCompaniesMicroserviceUrl: 'https://core.dev.mipaquete.com/deliverycompanies', // Ruta microservicio transportadoras desarrollo
    usersMicroserviceUrl: 'https://core.dev.mipaquete.com/users', // Ruta microservicio usuarios desarrollo
    routesMicroserviceUrl: 'https://core.dev.mipaquete.com/routes', // Ruta microservicio rutas desarrollo
    sendingsMicroserviceUrl: 'https://core.dev.mipaquete.com/sendings', // Ruta microservicio envios desarrollo

    transactionalPageUrl: 'https://app.dev.mipaquete.com', // Ruta página mi paquete reloaded
    collectionPaidUrl: 'https://mipaquete.com/solucionesusuario/consulta_recaudos/', // collections paid platform development
    adminUrl: 'https://administrativo.dev.mipaquete.com', // administrative platform development
    operationsUrl: 'https://operaciones.dev.mipaquete.com', // operation platform url

    urlConfigFile: 'https://config-mpr-dev.s3.amazonaws.com/config.dev.json', // Ruta archivo de configuracion desarrollo
    urlMassiveNoveltiesTemplateExcel: 'https://docuemnts-mpr-dev.s3.amazonaws.com/templates/noveltiesUpload.xlsx', // Ruta archivo de novedades masivo en desarrollo
  },
  PDN: {
    noveltiesMicroserviceUrl: 'https://core.mipaquete.com/novelties', // Ruta microservicio de novedades producción
    deliveryCompaniesMicroserviceUrl: 'https://core.mipaquete.com/deliverycompanies', // Ruta microservicio transportadoras producción
    usersMicroserviceUrl: 'https://core.mipaquete.com/users', // Ruta microservicio usuarios producción
    routesMicroserviceUrl: 'https://core.mipaquete.com/routes', // Ruta microservicio rutas producción
    sendingsMicroserviceUrl: 'https://core.mipaquete.com/sendings', // Ruta microservicio envios desarrollo

    transactionalPageUrl: 'https://app.mipaquete.com', // Ruta página mi paquete reloaded
    collectionPaidUrl: 'https://mipaquete.com/solucionesusuario/consulta_recaudos/', // collections paid platform production
    operationsUrl: 'https://operaciones.mipaquete.com',

    adminUrl: 'https://administrativo.mipaquete.com',
    urlConfigFile: 'https://config-mpr-prod.s3.amazonaws.com/config.prod.json', // Ruta archivo de configuracion producción
    urlMassiveNoveltiesTemplateExcel: 'https://docuemnts-mpr-prod.s3.amazonaws.com/templates/noveltiesUpload.xlsx', // Ruta archivo de novedades masivo en producción
  },
};

export default environment[process.env.REACT_APP_ENVIRONMENT];
