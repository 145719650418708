import React from 'react';
import PropTypes from 'prop-types';

import './leftSideMenuCards.scss';

const LeftSideMenuCards = ({
  img, label, elementRight, active, onClick, shadding, itemsHover,
}) => (
  <div
    className={`${itemsHover ? 'left-side-menu-card-hover' : 'left-side-menu-card'}
    ${active ? 'active' : ''}
    ${shadding ? 'shadding' : ''}`}
    onClick={onClick}
  >
    {img && (
      <div className={`${itemsHover ? 'container-img-hover' : 'container-img__left-side-menu-card'}`}>
        <img src={img} alt="Icon card" />
      </div>
    )}
    <span className={`${itemsHover ? 'text' : ''}`}>{label}</span>
    {elementRight}
  </div>
);

LeftSideMenuCards.propTypes = {
  onClick: PropTypes.func.isRequired,
  img: PropTypes.string,
  label: PropTypes.string.isRequired,
  elementRight: PropTypes.element,
  active: PropTypes.bool,
  shadding: PropTypes.bool,
  itemsHover: PropTypes.bool,
};

LeftSideMenuCards.defaultProps = {
  img: null,
  elementRight: null,
  active: false,
  shadding: false,
  itemsHover: false,
};

export default LeftSideMenuCards;
