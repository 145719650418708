/* eslint-disable prefer-regex-literals */
const lengthValidation = (value, str = '') => str.length < value;
const lengthStrict = (str = '', value = 10) => str.length === value;

const justNumber = (str = '') => {
  const regex = new RegExp(/^\d+$/gm);
  if (str === '') return true;
  return regex.test(str);
};

const isTheFirstValue = (str, stringValue) => str[0] === stringValue;

const cellPhoneMexicoValidation = (cellPhone) => (
  justNumber(cellPhone)
  && lengthValidation(11, cellPhone));

const cellPhoneColombiaValidation = (cellPhone) => (
  justNumber(cellPhone)
  && lengthValidation(11, cellPhone)
  && isTheFirstValue(cellPhone, '3')
);

const cellPhoneValidationsFunctions = (cellPhone) => ({
  170: () => cellPhoneColombiaValidation(cellPhone),
  484: () => cellPhoneMexicoValidation(cellPhone),
});

export const cellPhoneStrict = (cellPhone) => ({
  170: () => lengthStrict(cellPhone, 10),
  484: () => lengthStrict(cellPhone, 10),
});

export default cellPhoneValidationsFunctions;
