import cellPhoneValidationsFunctions, { cellPhoneStrict } from '../components/noveltiesModule/commons/validations/cellphone';

/* eslint-disable max-len */
export const toCurrencyCop = (mount) => new Intl.NumberFormat('es-CO', {
  style: 'currency',
  currency: 'COP',
  minimumFractionDigits: 0,
}).format(mount);

export const toCurrencyUsd = (mount) => new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 1,
}).format(mount);

export const MPCodeVerify = (code) => {
  const regMPCode = /^MP\d{1,}$/;
  // eslint-disable-next-line react/destructuring-assignment
  if (regMPCode.test(code)) return code.slice(2);
  return null;
};

export const extractToSpecialCharacter = (string, character) => {
  if (string) {
    return string.trim().split(character);
  }
  return null;
};

export const insertOrRemoveErrorFromList = (errorList, errorName, errorText, action) => {
  const indexOfFind = errorList.map((element) => element.name).indexOf(errorName);
  if (action === 'insert') {
    if (indexOfFind < 0) {
      errorList.push({
        name: errorName,
        error: errorText,
      });
    }
  } else if (action === 'remove') {
    if (indexOfFind !== -1) {
      errorList.splice(indexOfFind, 1);
    }
  }
};

export const capitalizeWords = (string) => {
  if (string) {
    return string.toLowerCase()
      .trim()
      .split(' ')
      .map((v) => v && v[0].toUpperCase() + v.substr(1))
      .join(' ');
  }
  return null;
};

export const removeAccents = (value) => value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

export const validationValue = (value, type, countryCode = '170') => {
  if (!value) {
    return {
      value,
      correct: false,
    };
  }

  if (type === 'cellphone' && (!cellPhoneStrict(value.toString())[countryCode]() || !cellPhoneValidationsFunctions(value.toString())[countryCode]())) {
    return {
      value,
      correct: false,
    };
  }

  if (type === 'name' && (value.toString().trim().length <= 2 || value.toString().trim().length >= 90)) {
    return {
      value,
      correct: false,
    };
  }

  if (type === 'address' && (value.toString().trim().length <= 5 || value.toString().trim().length >= 90)) {
    return {
      value,
      correct: false,
    };
  }

  if (type === 'guide' && (value.toString().length <= 4 || !/^\d*$/.test(value))) {
    return {
      value,
      correct: false,
    };
  }

  return {
    value: value.toString().trim(),
    correct: true,
  };
};

// separete accepts values with commas
export const validateFile = async (value, maxFiles, accepts, required) => {
  if (!value) {
    return {
      value,
      correct: false,
    };
  }

  if (Array.isArray(value)) {
    if (value.length > maxFiles) {
      return {
        value,
        correct: false,
      };
    }

    if (required && value.length < 1) {
      return {
        value,
        correct: false,
      };
    }

    if (accepts) {
      const acceptArray = accepts.split(',');
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < value.length; i++) {
        const ext = value[i].fileName?.split('.').reverse()[0];
        const exist = acceptArray.find((accept) => accept.toLowerCase().trim() === ext.toLowerCase().trim());
        if (!exist) {
          return {
            value,
            correct: false,
          };
        }
      }
    }

    if (value) {
      return {
        value,
        correct: true,
      };
    }
  }

  return {
    value,
    correct: false,
  };
};
export const addThousandPoint = (value) => {
  if (value !== '' && value !== undefined) {
    const currentValue = value.toString().split('').reverse();
    let count = 0;
    const newValue = [];
    currentValue.forEach((item) => {
      if (item !== '.') {
        if (
          count === 3
        ) {
          newValue.push('.');
          newValue.push(item);
          count = 0;
        } else {
          newValue.push(item);
        }
        count += 1;
      }
    });
    return newValue.reverse().join('');
  }
  return 0;
};

export default {
  MPCodeVerify,
  capitalizeWords,
  extractToSpecialCharacter,
  insertOrRemoveErrorFromList,
  addThousandPoint,
};
