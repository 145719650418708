import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import {
  backgroundFigureFullWidth, backgroundFigureWhite,
  backgroundFigureBlue, backgroundFigurePosition,
} from '../../utils/generals';

import './background.scss';

const Background = ({ history }) => {
  const [pathname, setPathname] = useState(null);

  useEffect(() => {
    setPathname(history.location.pathname);
  }, [history.location.pathname]);

  return (
    <div id="background">
      <div
        id="figure__background"
        className={`
          primary
          ${backgroundFigureFullWidth(pathname) ? 'full-width' : ''}
          ${backgroundFigureWhite(pathname) ? 'white' : ''}
          ${backgroundFigureBlue(pathname) ? 'accent' : ''}
          ${backgroundFigurePosition(pathname)}
        `}
      />
    </div>
  );
};

Background.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withRouter(Background);
