export const AUTH_IN = 'AUTH_IN';
export const AUTH_OUT = 'AUTH_OUT';
export const AUTH_UPDATE = 'AUTH_UPDATE';
export const AUTH_UPDATE_TOKEN = 'AUTH_UPDATE_TOKEN';

export const SHOW_MENU = 'SHOW_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';

export const LOAD_DATA_GLOBAL = 'LOAD_DATA_GLOBAL';
export const UPDATE_DATA_GLOBAL = 'UPDATE_DATA_GLOBAL';

export const IS_MOBILE_DEVICE = 'IS_MOBILE_DEVICE';
export const IS_NOT_MOBILE_DEVICE = 'IS_NOT_MOBILE_DEVICE';

export const getAction = (type, payload = null) => ({
  type,
  payload,
});
