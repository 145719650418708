/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export const setHeaderRequest = (token) => {
  if (token) {
    axios.defaults.headers.common.token = token;
  } else {
    delete axios.defaults.headers.common.token;
  }
  axios.defaults.headers.common['session-tracker'] = uuidv4();
};

export default {
  setHeaderRequest,
};
