/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import {
  routesMicroserviceUrl,
  customerKeyRoutesMicroservice,
} from '../constants';

/**
  * obtener listado de ciudades
*/
export const getLocationsService = () => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyRoutesMicroservice;
  axios.get(`${routesMicroserviceUrl}/getLocations`)
    .then((response) => resolve(response.data))
    .catch(({ response }) => reject(response));
});

export const getLocationsFilter = (params) => new Promise((resolve, reject) => {
  axios.defaults.headers.common['customer-key'] = customerKeyRoutesMicroservice;
  axios.get(`${routesMicroserviceUrl}/getLocationsFilter`, { params })
    .then(({ data }) => resolve(data))
    .catch((error) => reject(error));
});
