import { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';

import { transactionalPageUrl } from '../../constants';

const UrlControl = ({ history }) => {
  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    if (queryParams.intercommunicationData && window.location.origin !== transactionalPageUrl) {
      delete queryParams.intercommunicationData;
      history.replace(`${window.location.pathname}?${queryString.stringify(queryParams)}${window.location.hash}`);
    }
  }, [window.location]);

  return null;
};

UrlControl.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default withRouter(UrlControl);
