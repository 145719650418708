import { combineReducers } from 'redux';

import authReducer from './auth.reducer';
import menuReducer from './menu.reducer';
import globalDataReducer from './global.reducer';
import mediaQueryReducer from './mediaQuery.reducer';

export default combineReducers({
  auth: authReducer,
  menu: menuReducer,
  globalData: globalDataReducer,
  mediaQuery: mediaQueryReducer,
});
