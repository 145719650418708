import React from 'react';
import Imagotype from '../../assets/images/imagotype.svg';
import './loading.scss';

const Loading = () => (
  <div id="loading">
    <img src={Imagotype} alt="logo" />
    <div />
  </div>
);

export default Loading;
